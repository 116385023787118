import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Storage  {

    private readonly TOKEN_KEY = 'id_token';
    private readonly EXPIRE_KEY = 'expires_at';
    private readonly API_PATH = 'api_path';
    constructor() {}

    /**
     * set token to local storage
     */
    set(responseObj) {
        const expiresAt = Math.floor(Date.now() / 1000) + responseObj.expiresIn;
        localStorage.setItem(this.TOKEN_KEY, responseObj.token);
        localStorage.setItem(this.EXPIRE_KEY, JSON.stringify(expiresAt) );
    }

    setApiPath(path) {
      localStorage.setItem(this.API_PATH, path);
    }

    /**
     * remote items from local storage
     */
    clear() {
        localStorage.removeItem(this.TOKEN_KEY);
        localStorage.removeItem(this.EXPIRE_KEY);
        localStorage.removeItem(this.API_PATH);
    }

    /**
     * check if token in local storage is expired
     *
     * @returns true if token is valid, wrong if not available or expired
     */
    public isExpired(): boolean {
      return Math.floor(Date.now() / 1000) > parseInt(JSON.parse(localStorage.getItem(this.EXPIRE_KEY)));
    }
}
