import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingProvider {

  private isLoading = false;

  constructor(
    private loadingCtrl: LoadingController
  ) { }

  /**
   * Show a loading indicator.
   *
   * @param text      The text to show in the loading indicator
   * @param duration  The maximum duration for showing the loading indicator
   */
  async presentLoading(text: string, duration: number = 3000) {
    this.isLoading = true;
    return await this.loadingCtrl.create({
      message: text,
      duration
    }).then(a => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss().then(() => {});
        }
      });
    });
  }


  /**
   * Remove the loading indicator, if present.
   */
  dismissLoading() {
    if (this.isLoading) {
      this.loadingCtrl.dismiss({
        dismissed: true
      }).then( () => this.isLoading = false).catch(() => {});

    }
  }
}
