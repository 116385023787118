import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';
/*
  Generated class for the StatusProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: 'root'
})
export class StatusProvider {

  public isExerciseRunning: Observable<boolean>;
  private isExerciseRunningSubj: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {
    this.isExerciseRunning = this.isExerciseRunningSubj.asObservable();
  }

  /**
   * Sets the state of the observable indicating that an exercise is currently running.
   * @param isRunning   The new value of the Observable
   */
  setExerciseStatus(isRunning: boolean) {
    this.isExerciseRunningSubj.next(isRunning);
  }


}
