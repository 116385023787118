import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as Rx from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { ENV } from '../../../environments/environment';
import { Message } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class WebsocketProvider {

  public useSso = ENV.useSso;
  public baseUrl = ENV.baseUrl;
  // socket connection
  private socket: Socket;

  constructor() { }

  // connect to socket using jwt
  public connect(): void {

    if (this.useSso) {
      console.log(ENV.socketUrl)
      this.socket = io(this.baseUrl , {
        path: '/' + localStorage.getItem("api_path") + '/socket.io/',
        transportOptions: {
          polling: {
            extraHeaders: { Authorization: 'Bearer ' + localStorage.getItem("id_token")}
          }
        }
      });
    } else {
      this.socket = io(ENV.socketUrl , {
        transportOptions: {
          polling: {
            extraHeaders: { Authorization: 'Bearer ' + localStorage.getItem("id_token")}
          }
        }
      });
    }

  }

  public getMessages(): Rx.Subject<MessageEvent> {

    // observable which will observe any incoming messages from our socket.io server
    let observable = new Observable(observer => {
        this.socket?.on('message', (data: {type, text}) => {
          observer.next(data);
        });
        this.socket?.on('unauthorized', (error, callback) => {
          if (error.data.type === 'UnauthorizedError' || error.data.code == 'invalid_token') {
            // redirect user to login page perhaps or execute callback:
            callback();
            console.log('User token has expired');
          }
        });
    });

    let observer = {
        next: (data: Message) => {
            this.socket?.emit('message', data);
        },
    };

    return Rx.Subject.create(observer, observable);
  }

}
