import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Event, Result } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class ExerciseProvider {

  private result: Result = {
    checked: 0,
    createdAt: '',
    executed: 0,
    countExercisePerDay: null,
    date: null,
    event: null,
    videoFilePaths: null,
    questions: null,
    answers: null
  };

  private selectedEvent$$: BehaviorSubject<Event> = new BehaviorSubject<Event>(undefined);
  public selectedEvent$: Observable<Event> = this.selectedEvent$$.asObservable();

  constructor(
    public http: HttpClient
  ) { }

  public setStartTraining(date: string): void {
    this.result.startTraining = date;
  }

  public setStartExercise(date: string): void {
    this.result.startExercise = date;
  }

  public setEndExercise(date: string): void {
    this.result.endExercise = date;
  }

  public setEndTraining(date: string): void {
    this.result.endTraining = date;
  }

  public setVideosToResult(videoFilePaths: string[]) {
    this.result.videoFilePaths = videoFilePaths;
  }

  public getResult(): Result {
    return this.result;
  }

  public unsetResult(): void {
    this.result = {
      checked: 0,
      createdAt: "",
      executed: 0,
      countExercisePerDay: null,
      date: null,
      event: null,
      videoFilePaths: null,
      questions: null,
      answers: null
    };
  }

  public setSelectedEvent(event: Event): void {
    this.selectedEvent$$.next(event);
  }

  public getSelectedEvent(): Observable<Event> {
    return this.selectedEvent$;
  }

}
