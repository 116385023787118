import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Api } from '../api/api';
import { User } from '../user/user';
import { Event } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class EventProvider {

  private events$$: BehaviorSubject<Event[]> = new BehaviorSubject<Event[]>(undefined);

  public events$: Observable<Event[]> = this.events$$.asObservable();

  constructor(
    public http: HttpClient,
    private api: Api,
    private user: User
  ) {
   //this.fetchEvents();
  }

  public fetchEvents(): void {
    this.api.get('event/getAllExecutedEventsByPatientId', { patientid: this.user.user.id }).subscribe((events: Event[]) => {
      if (!events) {
        return;
      }
      this.events$$.next(events);
    });
  }

  public getAppointmentsForPatientById(): Observable<any> {
    return this.api.get('appointment/getAppointmentsForPatientById', { patientId: this.user.user.id });
  }

  public deleteFollowUpEventsForPrescribeOnce(event): void {
    this.api.post('event/deleteFollowUpEventsForPrescribeOnce', {id: event.eventSerie.id})
  }

  public getDoneEvents(): Observable<Event[]> {

    return this.events$;
  }

  public getGroupTherapyLink(appointmentId: string, participantId: string): Observable<any> {
    return this.api.get('groupTherapy/getGroupTherapyLinkFromMedicalSyn', {appointmentId, participantId}, {});
  }


}
