import { Injectable } from '@angular/core';
import { Api } from '../api/api';
import { Observable } from 'rxjs';
import { Storage } from '../storage/storage';
import { share } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class User {

  private _user: any | null = null;

  constructor(
    private readonly api: Api,
    private readonly router: Router,
    private readonly storage: Storage) { }

    /**
     * login user
     *
     * @param credentials to authenticate
     */
  public login(credentials: any): Observable<any> {

    let tUser = credentials.username;
    let useEmail = 'false';
    if (tUser.includes('@')) {
        useEmail = 'true';
    }

    const loginData = new FormData();
    loginData.append('username', credentials.username);
    loginData.append('password', credentials.password);
    loginData.append('subject', 'patient');
    loginData.append('useEmail', useEmail);
    return this.api.post('Login', loginData).pipe(share());
  }


  public loginWithQr(qrcode: string): Observable<any> {
      const qrData = new FormData();
      qrData.append('qrcode', qrcode);
      return this.api.post('QrLogin', qrData).pipe(share());
  }

  async defineApiPath(username: string) {
    return await this.api.ssoPost('getApiSlugForCmp', {username: username, role: 'patient'})
  }

  resetUrl() {
    this.api.resetUrl();
  }
  public acceptPatientDeclaration(): void {
    this.api.get('AcceptPatientDeclaration', {id : this._user.id})
    .subscribe((res: any) => {
        this._user.phase = res.phase;
          console.log('Sent Acceptance');
      });
  }

  /** change password for user, oldpassword to compare validity */
  public changePassword(oldPassword: string, newpassword: string): any {
    return this.api.post('patient/updatePassword', { patientid: this.user.id, oldpassword:  oldPassword, newpassword });
  }

  public changeEmail(newEmail: string): any {
      return this.api.post('patient/updateEmail', { patientId: this.user.id, email: newEmail });
  }

  public logOut(patientId: string): any {
    return this.api.post('patient/logOut',{ patientId: this.user.id});
  }

  public sendPasswordForgotten(email: string, lang: string): any {
    return this.api.post('patient/sendForgotPasswordHash', { email: email, lang: lang }, { withCredentials: false }).pipe(share());
  }

  public sendEmailConfirmation(email: string, patientId: string): any {
    return this.api.post('patient/sendEmailConfirmation', { email: email, patientId: patientId, patient: true});
  }

  public async checkResetPasswordHash(resetPasswordToken: string) {
    return this.api.post('patient/checkHashForReset', { resetPasswordToken: resetPasswordToken }, { withCredentials: false }).pipe(share());
  }

  public async confirmEmailByHash(confirmEmailToken: string) {
      return this.api.post('patient/verifyEmail', { confirmEmailToken: confirmEmailToken }, { withCredentials: false}).pipe(share());
  }

  public async verifyEmailWithOptIn(confirmEmailToken: string, email: string, optIn: boolean) {
      return this.api.post('patient/verifyEmailWithOptIn', {
        confirmEmailToken: confirmEmailToken,
        email: email,
        optIn: optIn
      }, { withCredentials: false }).pipe(share());
  }

  public async getPatientByVerificationToken(confirmEmailToken: string) {
      return this.api.post('patient/getPatientByVerificationToken', { confirmEmailToken: confirmEmailToken}, { withCredentials: false}).pipe(share());
  }

  public async resetPasswordByHash(resetPasswordToken: string, newPassword: string) {
    return this.api.post('patient/resetPasswordByHash', { resetPasswordToken: resetPasswordToken, newPassword: newPassword}, { withCredentials: false}).pipe(share());
  }

  public async resetPasswordById(patientId: string, newPassword: string) {
    return this.api.post('patient/resetPasswordById', {patientId: patientId, newPassword: newPassword}).pipe(share());
  }

  public async changeLanguage(patientId: string, lang: string) {
    return this.api.post('patient/changeLang', { patientId: patientId, lang: lang }).pipe(share());
  }

  public async loginToNeuroNation(patientId: string) {
    return this.api.post("neuroNation/login", { patientId: patientId}).pipe(share());
  }

  public logNeuroResult(patientId: string, eventId: string, type: string, content: object) {
    return this.api.post("neuroNation/createNeuroResult", {
      patientId: patientId,
      eventId: eventId,
      type: type,
      content: content
    });
  }

  public logout(): void {

      this.logOut(this._user.id).subscribe({
        next: response => {
          this._user = null;
          this.storage.clear();
          this.router.navigate(['/login'], { replaceUrl: true})
            .then(() => {
              // dirty hack for clearing ionic content
              location.reload();
            });
        },
        error: (e) => {

            this._user = null;
            this.storage.clear();
            this.router.navigate(['/login'], { replaceUrl: true})
              .then(() => {
                // dirty hack for clearing ionic content and also clearing with failed logout
                location.reload();
              });
        }
      });

 }

  public isLoggedIn() {
    return this._user !== null && !this.isExpired();
  }

  public isExpired() {
    return this.storage.isExpired();
  }

  /**
   * Process a login/signup response to store user data
   */
   public set user(user) {
    if (!user) {
      this._user = null;
    } else {
      this._user = user;
    }
  }

  /**
   * @return user
   */
  public get user(): any {
    return this._user;
  }
}
