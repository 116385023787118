import { Component, ViewChild } from '@angular/core';
import { Platform, MenuController, IonNav } from '@ionic/angular';
import { timer } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  /**
   * first page when entering application
   */
  @ViewChild(IonNav) nav: IonNav;

  public pages: any[] = [
    {title: 'Tutorial', component: 'TutorialPage'},
    {title: 'Welcome', component: 'WelcomePage'},
    {title: 'Tabs', component: 'TabsPage'},
    {title: 'Content', component: 'ContentPage'},
    {title: 'Login', component: 'LoginPage'},
    {title: 'Signup', component: 'SignupPage'},
    {title: 'Master Detail', component: 'EventListOverviewPage'},
    {title: 'Settings', component: 'SettingsPage'},
    {title: 'Search', component: 'SearchPage'},
    {title: 'Surveys', component: 'SurveyPage'},
    {title: 'Relax', component: 'EvoRelaxPage'},
    {title: 'Videoplayer', component: 'PlayerPage'},
    {title: 'VideoRecorder', component: 'CaptureVideoPage'},
    {title: 'Feedback', component: 'FeedbackPage'},
    {title: 'Recorder', component: 'RecordVideoPage'},
    {title: 'Consultation', component: 'ConsultationPage'},
    {title: 'Diaries', component: 'DiaryPage'},
    {title: 'VideoCallPage', component: 'VideoCallPage'},
    {title: 'NeuroNationPage', component: 'NeuroNationPage'},
    {title: 'ModalQrCodePage', component: 'ModalQrCodePage'}
  ];

  constructor(private menuCtrl: MenuController,
              public platform: Platform) {
    platform.ready().then((source) => {
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.
      void this.menuCtrl.enable(false);
    });
  }

  /**
   * set root page when entering page
   */
  public openPage(page): void {
    // Reset the content nav to have just this page
    // we wouldn't want the back button to show in this scenario
    void this.nav.setRoot(page.component);
  }

}
