import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import {ModalQrCodePageModule} from "./modal-qrcode/modal-qrcode.module";

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then( m => m.TabsPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'reset-by-token/:token/:lang',
    loadChildren: () => import('./reset-by-token/reset-by-token.module').then( m => m.ResetByTokenPageModule)
  },
  {
    path: 'confirm-email/:token/:lang',
    loadChildren: () => import('./confirm-email/confirm-email.module').then( m => m.ConfirmEmailPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'modal-qrcode',
    loadChildren: () => import('./modal-qrcode/modal-qrcode.module').then( m => m.ModalQrCodePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'modal-item-infos',
    loadChildren: () => import('./modal-item-infos/modal-item-infos.module').then( m => m.ModalItemInfosPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'modal-patient-declaration',
    loadChildren: () => import('./modal-patient-declaration/modal-patient-declaration.module').then( m => m.ModalPatientDeclarationPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'modal-change-password',
    loadChildren: () => import('./modal-change-password/modal-change-password.module').then(m => m.PasswordModalPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'modal-new-password',
    loadChildren: () => import('./modal-new-password/modal-new-password.module').then(m => m.NewPasswordModalPageModule),
    canActivate: [AuthGuardService]
  },
  {
      path: 'modal-change-email',
      loadChildren: () => import('./modal-change-email/modal-change-email.module').then(m => m.ModalChangeEmailModule),
      canActivate: [AuthGuardService]
  },
  {
    path: 'modal-confirm-email',
    loadChildren: () => import('./modal-confirm-email/modal-confirm-email.module').then(m => m.ModalConfirmEmailModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'modal-imprint',
    loadChildren: () => import('./modal-imprint/modal-imprint.module').then(m => m.ModalImprintPageModule)
  },
  {
    path: 'modal-patient-reminder',
    loadChildren: () => import('./modal-patient-reminder/modal-patient-reminder.module').then(m => m.ModalPatientReminderModule)
  },
  {
    path: 'modal-logout',
    loadChildren: () => import('./modal-logout/modal-logout.module').then( m => m.ModalLogoutPageModule)
  },
  {
    path: 'evo-relax',
    loadChildren: () => import('./evo-relax/evo-relax.module').then( m => m.EvoRelaxPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'execution',
    loadChildren: () => import('./execution/execution.module').then( m => m.ExecutionPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'modal-feedback-complete',
    loadChildren: () => import('./modal-feedback-complete/modal-feedback-complete.module').then( m => m.ModalFeedbackCompletePageModule)
  },
  {
    path: 'modal-forgot-password',
    loadChildren: () => import('./modal-forgot-password/modal-forgot-password.module').then( m => m.ModalForgotPasswordPageModule)
  },
  {
    path: 'survey',
    loadChildren: () => import('./survey/survey.module').then( m => m.SurveyPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'diary',
    loadChildren: () => import('./diary/diary.module').then( m => m.DiaryPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'videocall',
    loadChildren: () => import('./videocall/videocall.module').then( m => m.VideocallPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'neuronation',
    loadChildren: () => import('./neuronation/neuronation.module').then( m => m.NeuroNationPageModule),
    canActivate: [AuthGuardService]
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
