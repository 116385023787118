import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Api } from "../api/api";
import { User } from "../user/user";

@Injectable({
    providedIn: 'root'
})
export class Internationalization {

    private return: string;

    constructor(
        private readonly translate: TranslateService,
        private api: Api,
        private user: User
    ) { }

    async setInternationalization(code: string) {

      if (code && this.translate.getLangs().indexOf(code) !== -1) {

        if (this.user.user && this.user.user.lang !== code) {
          await (await this.user.changeLanguage(this.user.user.id, code)).subscribe((res) => console.log('language set'));
        }
        this.translate.use(code);
        moment.locale(code);
        this.return = code;
      } else {
        this.translate.use('de');
        moment.locale('de');
        this.return = 'de';
      }
      return this.return;
    }
}
