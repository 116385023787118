import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, retryWhen } from 'rxjs/operators';
import { ENV } from '../../../environments/environment';
import { Storage } from '../storage/storage';

/**
 * Api is a generic REST Api handler. Set your API url first.
 */
@Injectable({
  providedIn: 'root'
})
export class Api {
  url: string = ENV.apiUrl; //url: string = 'https://evocloud.de:49160/api/v1'; //  url: string =  'https://evocare.org/cmp/api/v1'
  saveUrl: string = ENV.apiUrl;
  baseUrl: string = ENV.baseUrl; //baseUrl: string = 'https://evocloud.de:49160'; // baseUrl: string = 'https://evocare.org:49160'
  cdnUrl: string = ENV.cdnUrl;
  ssoUrl: string = ENV.ssoUrl;

  ssoUser: string = ENV.ssoUser;
  ssoPassword: string = ENV.ssoPassword;
  apiPath = ''; //TODO make check and use it
  constructor(
    public http: HttpClient,
    private readonly storage: Storage
  ) {

  }

  public get<T>(endpoint: string, params?: any, reqOpts?: {headers?: HttpHeaders, params?: HttpParams, withCredentials?:boolean }): Observable<T> {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
        withCredentials: true  //#TODO WITH CREDENTIALS??
      };
    }

    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      for (let k in params) {
        reqOpts.params = reqOpts.params.set(k, params[k]);
      }
    }

    return this.http.get<T>(this.url + '/' + endpoint, reqOpts);
  }

  public post(endpoint: string, body: any, reqOpts?: any, attempts?: number) {
    let remainingAttempts = attempts ? attempts : 1;
    let retryDelay = 1000;

    if (!reqOpts) {
      reqOpts = {
        withCredentials: true
      };
    }

    return this.http.post(this.url + '/' + endpoint, body, reqOpts).pipe(
      retryWhen((errors: Observable<any>) => errors.pipe(
        mergeMap((err) => {
          remainingAttempts--;
          if (remainingAttempts > 0) {
            // Increasing delay time for retry (2s => 4s => 8s => 16s => ...)
            retryDelay = retryDelay * 2;
            return of(err).pipe(delay(retryDelay));
          }
          return throwError(() => err);
        })
      )));
  }

  public async ssoPost(endpoint: string, body: any) {
    const path = await firstValueFrom(this.http.post(this.ssoUrl + '/' + endpoint,
                                    body,
                            { headers: { 'Content-Type':'application/json','Authorization':'Basic ' + btoa(this.ssoUser + ":" + this.ssoPassword) } }
                                    ));
    this.url = this.baseUrl + '/' + path + '/api/v1';

    this.storage.setApiPath(path);
  }

  public resetUrl() {
    this.url = this.saveUrl;
  }

  public put(endpoint: string, body: any, reqOpts?: any) {
    return this.http.put(this.url + '/' + endpoint, body, reqOpts);
  }

  public delete(endpoint: string, reqOpts?: any) {
    return this.http.delete(this.url + '/' + endpoint, reqOpts);
  }

  public patch(endpoint: string, body: any, reqOpts?: any) {
    return this.http.patch(this.url + '/' + endpoint, body, reqOpts);
  }
}
