import { AppRoutingModule } from './app-routing.module';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Camera } from '@ionic-native/camera/ngx';
import { Network } from '@ionic-native/network/ngx';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicRouteStrategy, IonicModule } from '@ionic/angular';
import { User, Api } from './services';
import { AppComponent } from './app.component';
import { ProgressBarModule } from 'angular-progress-bar';
import { FileUploaderProvider } from './services/file-uploader/file-uploader';
import { AppUpdate } from '@ionic-native/app-update/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { StatusProvider } from './services/status/status';
import { ResultEventProvider } from './services/result-event/result-event';
import { AuthInterceptorProvider } from './services/auth-interceptor/auth-interceptor';
import { WebsocketProvider } from './services/websocket/websocket';
import { MessageProvider } from './services/message/message';
import { NotificationProvider } from './services/notification/notification';
import { DateFormatProvider } from './services/date-format/date-format';
import { LoadingProvider } from './services/loading/loading';
import { MediastreamProvider } from './services/mediastream/mediastream';
import { ExerciseProvider } from './services/excercise/excercise';
import { TherapyUnitProvider } from './services/therapy-unit/therapy-unit';
import { EventProvider } from './services/event/event';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { PipesModule } from './pipes/pipes.module';
import { ComponentsModule } from './components/components.module';
import { pageTransition } from "./page-transition";
import { AppInitializerFactory } from './factories/app.initializer.factory';
import 'moment/locale/de';
import 'moment/locale/ja';
import { ModalQrCodePageModule } from "./modal-qrcode/modal-qrcode.module";

/**
 * to use translation feature, load translation files from assets folder
 */
export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ProgressBarModule,
    ReactiveFormsModule,
    ComponentsModule,
    PipesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    IonicModule.forRoot({
      scrollAssist: false,
      mode: 'ios',
      animated: true,
      swipeBackEnabled: false,
      navAnimation: pageTransition,
    }),
    IonicStorageModule.forRoot()
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AppComponent
  ],
  providers: [
    Api,
    User,
    Camera,
    ProgressBarModule,
    ModalQrCodePageModule,
    StatusProvider,
    AppVersion,
    PipesModule,
    /*// Keep this to enable Ionic's runtime error handling during development
    { provide: ErrorHandler, useClass: IonicErrorHandler },*/
    FileUploaderProvider,
    Network,
    StatusProvider,
    ResultEventProvider,
    WebsocketProvider,
    MessageProvider,
    AuthGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorProvider,
      multi: true
    },
    NotificationProvider,
    DateFormatProvider,
    LoadingProvider,
    MediastreamProvider,
    ExerciseProvider,
    TherapyUnitProvider,
    EventProvider,
    AppUpdate,
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializerFactory,
      deps: [TranslateService],
      multi: true
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ]
})
export class AppModule { }
