import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Api } from '../api/api';
import { User } from '../user/user';
import { TherapyUnit } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class TherapyUnitProvider {

  private therapyUnits$$: BehaviorSubject<TherapyUnit[]> = new BehaviorSubject<TherapyUnit[]>(undefined);

  public therapyUnits$: Observable<TherapyUnit[]> = this.therapyUnits$$.asObservable();

  constructor(
    public http: HttpClient,
    private api: Api,
    private user: User
  ) {}

  public fetchTherapyUnits(): void {
    this.api.get('therapyUnit/getAllTherapyUnitsForPatient', { patientid: this.user.user.id }).subscribe((therapyUnits: any) => {
      if (!therapyUnits) {
        return;
      }

      this.therapyUnits$$.next(therapyUnits);
    });
  }

  public getTherapyUnits(): Observable<TherapyUnit[]> {
    return this.therapyUnits$;
  }

  public getTherapyUnitById(id: string): Observable<any> {
    return this.api.get('therapyUnit/getTherapyUnitById', { id: id });
  }

}
