import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ModalQrCodePageRoutingModule } from './modal-qrcode-routing.module';
import { ModalQrCodePage } from './modal-qrcode.page';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from "../components/components.module";

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    IonicModule,
    ModalQrCodePageRoutingModule,
    TranslateModule
  ],
  declarations: [ModalQrCodePage]
})
export class ModalQrCodePageModule {}
