import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { lastValueFrom } from 'rxjs';
import { ENV } from 'src/environments/environment';

export function AppInitializerFactory(translate: TranslateService) {
  return () => {
    translate.setDefaultLang('de');
    translate.addLangs(ENV.availableLanguages);
    moment.locale(document.documentElement.lang || translate.getDefaultLang())
    const selectedTranslation = translate.use(document.documentElement.lang);
    return lastValueFrom(selectedTranslation);
  };
}