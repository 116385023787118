import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormControlName, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-multi-step-form',
  templateUrl: './multi-step-form.component.html',
  styleUrls: ['./multi-step-form.component.scss']
})
export class MultiStepFormComponent implements OnInit {
  @Input() formContent: any;

  @Output() readonly formSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() changePage: EventEmitter<number> = new EventEmitter<number>();

  activeStepIndex: number;
  currentFormContent: Array<any>;
  formData: any;
  formFields: Array<Array<string>>;
  masterFormFields: Array<string>;
  stepItems: Array<any>;
  masterForm: Array<FormGroup>;
  counter = 0;
  limitNumber = 3;
  constructor(
    private readonly _formBuilder: FormBuilder,
    private _cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.activeStepIndex = 0;
    this.masterForm = [];
    this.currentFormContent = [];
    this.formFields = [];
    this.stepItems = this.formContent;
    this.stepItems.forEach((data, i) => {
      this.currentFormContent.push(this.stepItems[i].data); // holds name, validators, placeholder of all steps
      this.formFields.push(Object.keys(this.currentFormContent[i])); // holds string values for each field of all steps
      this.masterForm.push(this.buildForm(this.currentFormContent[i])); // holds all form groups
    });
  }

  // build separate FormGroups for each form
  buildForm(currentFormContent: any): FormGroup {
    const formDetails = Object.keys(currentFormContent).reduce((obj, key) => {
      obj[key] = ['', this.getValidators(currentFormContent[key])];
      return obj;
    }, {});

    return this._formBuilder.group(formDetails);
  }


  // get validator(s) for each field, if any
  getValidators(formField: any): Validators {
    const fieldValidators = Object.keys(formField.validations).map(
      validator => {
        if (validator === 'required') {
          return Validators[validator];
        } else if (validator === 'checkTextInTextInput') {
          return this.checkTextInTextInput;
        } else if (validator === 'checkChecked') {
          return this.checkCheckBoxCount;
        } else {
          return Validators[validator](formField.validations[validator]);
        }
      }
    );

    return fieldValidators;
  }

  // get validation error messages per error, per field
  getValidationMessage(formIndex: number, formFieldName: string): string {
    const formErrors = this.masterForm[formIndex].get(formFieldName).errors;
    const errorMessages = this.currentFormContent[formIndex][formFieldName]
      .errors;
    const validationError = errorMessages[Object.keys(formErrors)[0]];

    return validationError;
  }

  goToStep(step: string): void {
    this.activeStepIndex =
      step === 'prev' ? this.activeStepIndex - 1 : this.activeStepIndex + 1;
    this.setFormPreview();
  }

  triggerScrollUp() {
    this.changePage.emit(this.activeStepIndex);
  }

  setFormPreview(): void {
    this.formData = this.masterForm.reduce(
      (masterForm, currentForm) => ({ ...masterForm, ...currentForm.value }),
      {}
    );
    this.masterFormFields = Object.keys(this.formData);
  }

  onFormSubmit(): void {
    this.formSubmit.emit(this.formData);
  }

  trackByFn(index: number): number {
    return index;
  }

  checkValue(event: any, target: any){
    const item = <HTMLInputElement>document.getElementById(target);
    if (item.checked) {
      item.value = event.target.value;
    } else {
      event.target.value = '';
    }

  }

  checkRadioValue(target: any){
    const item = <HTMLInputElement>document.getElementById(target);
    if (item.checked) {
      return true;
    } else {
      return false;
    }

  }

  checkNonValue(event: any, target: any){
    let item = <HTMLInputElement>document.getElementById(target);
    if (!item.checked) {
      item.value = event.target.value;
    } else {
      event.target.value = '';
    }

  }

  checkGroupCheckBox(event: any, target: any){
    let targets = target.split(',');
    if (event.target.checked) {
      targets.forEach((t) => {
       this.masterForm[this.activeStepIndex].get(t).clearValidators()
        this.masterForm[this.activeStepIndex].get(t).reset()
      })
    } else {
      targets.forEach(t => {
        this.masterForm[this.activeStepIndex].get(t).setValidators([Validators.required])
        this.masterForm[this.activeStepIndex].get(t).setErrors({'required':true});
        console.log(this.masterForm[this.activeStepIndex].get(t))
      })
    }
    this.masterForm[this.activeStepIndex].updateValueAndValidity();
  }

  controlRequiredForGroupBySelect(event: any, target: any, notValue: string){
    let targets = target.split(',');

    if (event.target.value === notValue) {
      targets.forEach(t => {
            this.masterForm[this.activeStepIndex].get(t).removeValidators([Validators.required]);
            this.masterForm[this.activeStepIndex].get(t).setValue('');
          })
    } else {
      targets.forEach(t => {
        this.masterForm[this.activeStepIndex].get(t).addValidators([Validators.required]);
        this.masterForm[this.activeStepIndex].get(t).updateValueAndValidity();
      })
    }
    this.masterForm[this.activeStepIndex].updateValueAndValidity();
  }

  controlForRange(e, field, id) {
    console.log('change')
    let check = field.check;
    let errorMessage = field.checkError;
    const targetElement = document.getElementById(id);
    if ( check != '') {
      if(this.masterForm[this.activeStepIndex].get(check).value === '' || this.masterForm[this.activeStepIndex].get(check).value === 'Keine') {
        this.masterForm[this.activeStepIndex].get(id).setValue(errorMessage);
        targetElement.classList.remove('c-red','c-yellow','c-green')
        return;
      }
    }
    let outPut = '';
    field.options.forEach((ele: { number: string; name: string; }) => {
      if (ele.number == e.target.value) {
        outPut = ele.number + ' ' + ele.name;
        if (parseInt(ele.number) < 11) {
          targetElement.classList.remove('c-red','c-yellow');
          targetElement.classList.add('c-green');
        } else if (parseInt(ele.number) > 10 && parseInt(ele.number) < 16 ) {
          targetElement.classList.add('c-yellow');
          targetElement.classList.remove('c-green','c-red');
        } else if (parseInt(ele.number) > 15) {
          targetElement.classList.add('c-red');
          targetElement.classList.remove('c-yellow','c-green');
        }
        return;
      }
    })
    this.masterForm[this.activeStepIndex].get(id).setValue(outPut);
    this.masterForm[this.activeStepIndex].get(id).setErrors(null);
  }


  checkTextInput(event: any, field: any) {

    const target = <HTMLInputElement>document.getElementById(field);
      if (target.value !== '') {
        event.target.checked = true;
        event.target.value = target.value;
      }
  }

  addRequired(event, field) {
    this.masterForm[this.activeStepIndex].get(field).setValidators([Validators.required]);
    this.masterForm[this.activeStepIndex].get(field).updateValueAndValidity();
  }

  removeRequired(event, field) {
    this.masterForm[this.activeStepIndex].get(field).clearValidators();
    this.masterForm[this.activeStepIndex].get(field).setValue('');
    this.masterForm[this.activeStepIndex].get(field).updateValueAndValidity();
  }

  emptyTextInput(event, field, i) {
    const target = <HTMLInputElement>document.getElementById(field);
    target.value = '';
  }

  checkForThree(target) {
      const item = <HTMLInputElement>document.getElementById(target);
      if (this.counter === this.limitNumber && item.checked === false) {
        return false;
      } else {
        return true;
      }
  }

  checkCheckBoxCount() {
      if (document.querySelectorAll('input[type="checkbox"]:checked').length === 4) {
        return { checkChecked: {value: 'too many'} };
      } else {
        return null;
      }
  }

  checkTextInTextInput() {
      const target = <HTMLInputElement>document.getElementById('diagnosis6text');
      const orgTarget = <HTMLInputElement>document.getElementById('diagnosis6');
      if (target && orgTarget) {
        if ( target.value === '' && orgTarget.checked) {
          return { checkTextInTextInput: {value: 'no text'} };
        }
          return null;

      }
  }

  checkedState(event, checkBox) {
    if(event.target.checked === true){
      if(this.counter < 3){
        this.counter++;
      } else {
        //event.target.checked = false;
      }
    } else if (this.counter > 0){
      this.counter--;
    }
    console.log(this.counter);
  }
}
