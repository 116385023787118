import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MediastreamProvider {

  private mediastream: MediaStream;

  constructor() { }

  /**
   * @returns mediastraeam
   */
  public getMediastream(): MediaStream {
    return this.mediastream;
  }

  /**
   * set media stream and stop old one.
   */
  public setMediaStream(stream: MediaStream): void {
    this.stopMediastream();
    this.applyConstrains(stream);
    this.mediastream = stream;
  }

  /**
   * stop media stream by stopping its tracks
   */
  public stopMediastream(): void {
    if (this.mediastream) {
      const tracks = this.mediastream.getTracks();
      if (tracks && tracks !== null) {
        tracks.forEach(function(track) {
          track.stop();
        });
      }
      this.mediastream = null;
    }
  }

  public applyConstrains(stream: MediaStream): void {
    const tracks = stream.getTracks();
    if (tracks && tracks !== null) {
      tracks.forEach(function(track) {
        return track.applyConstraints({aspectRatio: 1.777777778});
      });
    }
    this.mediastream = null;
  }
}
