import { Component, ViewEncapsulation, Renderer2, ElementRef, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'overlay',
  templateUrl: 'overlay.html',
  styleUrls: ['./overlay.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OverlayComponent implements OnInit, AfterViewInit {
  public stepWidth: number;
  public isOverlayActive = false;
  public displayText: any = 4;
  public isAnimate = false;

  @Input()
  public counter: any;

  @Input()
  public videoHeight: number;

  @Output()
  public finished: EventEmitter<void> = new EventEmitter<void>();

  constructor(private readonly translate: TranslateService) { }

  ngOnInit() {
    this.isOverlayActive = true;
    this.stepWidth = 100 / this.counter;
  }

  ngAfterViewInit() {
    this.displayText = this.counter;
    this.isAnimate = true;
    setTimeout(()=>{
      this.countDown();
    }, 200);
  }

  /**
   * Recursive countdown starting with the input counter. Emits a finished event on reaching 0.
   */
  countDown() {
    setTimeout(() => {
      this.counter--;
      this.displayText = this.counter;
      setTimeout(()=>{
        this.isAnimate = true;
      }, 200);
      if (this.counter <= 0) {
        this.isAnimate = false;
        this.displayText = this.translate.instant('Therapy.CountdownEnd')+'<span>.</span>';
        setTimeout(()=>{
          this.isAnimate = true;
          setTimeout(()=>{
            this.finished.emit();
            this.isOverlayActive = false;
          }, 1000);
        }, 200);
      }
      else {
        this.isAnimate = false;
        this.countDown();
      }
    }, 1000);
  }
}
