export const ENV = {
  availableLanguages: ['de'],
  production: true,
  apiUrl: "https://cmp.tor.evocare.org/api/v1",
  baseUrl: "https://patient.tor.evocare.org",
  cdnUrl: "https://cmp.evocare.de/cdn/",
  socketUrl: "https://cmp.tor.evocare.org",
  useSso: false,
  ssoUrl: "https://prm.evocare.org/prm/api/v1",
  ssoUser: "api_medical",
  ssoPassword: "api_medical_pass",
};
