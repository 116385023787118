
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'localeDate' })
export class LocaleDatePipe implements PipeTransform {
    transform(value: string | Date, format = 'L'): string {
        if (format == "LMD") {
            let LMD = moment(value).format('L'); //#09/04/1986
            const yi = LMD.indexOf(moment(value).format('YYYY'));
            if (yi === LMD.length - 4) {
                LMD = LMD.slice(0, -5)
            }
            if (yi === LMD.length - 5) {
                LMD = LMD.slice(0, -6)
            }
            if (yi === 0) {
                LMD = LMD.substring(5);
            }
            return LMD;
        }
        return moment(value).format(format);
    }
}