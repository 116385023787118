import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay, mergeMap, retryWhen } from 'rxjs/operators';
import { of } from 'rxjs';
import { Api } from '../api/api';
import { User } from '../user/user';
import { throwError } from 'rxjs';

/** Maximum attempts for trying to upload a file. */
const MAX_UPLOAD_ATTEMPTS = 5;

@Injectable({
  providedIn: 'root'
})
export class FileUploaderProvider {

  /**
   * Uploading state as a BehaviorSubject.
   * True, if files are currently being uploaded. False, if upload is finished or there is no current upload process.
   */
  private uploading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /**
   * Uploading state as an Observable for subscriptions in components.
   * True, if files are currently being uploaded. False, if upload is finished or there is no current upload process.
   */
  public uploading$: Observable<boolean> = this.uploading$$.asObservable();

  constructor(
    public http: HttpClient,
    private api: Api,
    private user: User
  ) { }

  /**
   * Get an Observable of the current state of uploads.
   * @returns     The state of the uploads
   */
  public getUploadState(): Observable<boolean> {
    return this.uploading$;
  }

  /**
   * upload video from physiotherapy, evo relax.
   * Retry function.
   * Set video upload progress to provider
   * @params to be uploaded blob and filename
   */
  public uploadResultMedia(blob: Blob, fileName: string): void {
    //console.log('upload video', fileName, blob, (new Date()).toLocaleTimeString());
    let remainingAttempts = MAX_UPLOAD_ATTEMPTS;
    let retryDelay = 1000;

    // Set uploading state to true
    this.uploading$$.next(true);

    const reqOpts = {
      headers: new HttpHeaders().set('evomedia', fileName)
    };
    this.http.post(this.api.url + '/SaveResultMedia', blob, reqOpts).pipe(
      retryWhen((errors: Observable<any>) => errors.pipe(
        mergeMap((err) => {
          remainingAttempts--;
          // TODO: check for error type
          if (remainingAttempts > 0) {
            // Increasing delay time for retry (2s => 4s => 8s => 16s => ...)
            retryDelay = retryDelay * 2;
            //console.log('retry #' + (MAX_UPLOAD_ATTEMPTS - remainingAttempts) + '/' + (MAX_UPLOAD_ATTEMPTS - 1) + ' in ' + (retryDelay/1000) + 's', (new Date()).toLocaleTimeString());
            return of(err).pipe(delay(retryDelay));
          }
          //return Observable.throw(err);
          return throwError(err);
        })
      ))
    ).subscribe(
      () => {
        //console.log('Success uploading video', fileName, (new Date()).toLocaleTimeString());
        // Set uploading state to false
        this.uploading$$.next(false);
      },
      (err) => {
        console.error(err);
        // Set uploading state to false
        this.uploading$$.next(false);
      }
    );
  }

  /**
   * delete videos on server by its filenames
   * @params the files to be deleted
   */
  public deleteResultMedia(fileNames: string[]): void {
    //console.log('Delete recorded videos', (new Date()).toLocaleTimeString());

    if (fileNames && fileNames.length) {
      this.http.post(this.api.url + "/result/deleteVideos", { patientid: this.user.user.id, filePaths: fileNames }).subscribe(
        () => console.log('Videos deleted', (new Date()).toLocaleTimeString()),
        (err) => console.error(err)
      );
    }
  }

}
