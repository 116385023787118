import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Api } from '../api/api';
import { User } from '../user/user';
import { Event, Result } from '../../models';
import { ENV } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResultEventProvider {

  /** Current event list for the patient as a BehaviorSubject for manually assigning new values. */
  private events$$: BehaviorSubject<Event[]> = new BehaviorSubject<Event[]>(undefined);
  /** Current event list for the patient as an Observable for subscriptions in components. */
  public events$: Observable<Event[]> = this.events$$.asObservable();

  constructor(
    public http: HttpClient,
    private api: Api,
    private user: User
  ) { }

  /**
   * Fetch the list of events by sending a request to get todays events for the patient.
   */
  public fetchEvents(): void {
    const cdnPath = this.api.cdnUrl;
    //const cdnPath = "https://evocare.org:49160/cdn";
    //const cdnPath = "https://evocloud.de:49160/cdn";

    this.api.get('event/getAllEventsByPatientIdToday', { patientid: this.user.user.id}).subscribe((events: Event[]) => {
      if (!events) {
        return;
      }
      events.forEach((ev: Event) => {
        // Set the path for the audio, video and image files
        if (ev.eventSerie.item.audioId) {
          ev.eventSerie.item.audioId = cdnPath + ev.eventSerie.item.audioId;
        }
        if (ev.eventSerie.item.videoId) {
          ev.eventSerie.item.videoId = cdnPath + ev.eventSerie.item.videoId;
        }
        if (ev.eventSerie.item.imageIds) {
          for (let i = 0; i < ev.eventSerie.item.imageIds.length; i++) {
            ev.eventSerie.item.imageIds[i] = cdnPath + ev.eventSerie.item.imageIds[i];
          }
        }

        // Calculate the duration of the event
        const treatment = ev.eventSerie.item.treatment.name;
        if (treatment === 'Physiotherapy') {
          ev.duration = this.getExerciseDuration(ev);
        }
        else if ( treatment === 'Feed') {
          this.getAudioDuration(ev.eventSerie.item.audioId).then((dur: number) => {
            if (dur) {
              const splittedDuration: string[] = dur.toString().split('.');
              ev.duration = parseInt(splittedDuration[0], 10);
            }
            else {
              ev.duration = 0;
            }
          }).catch((err) => {
            console.error(err);
            ev.duration = 0;
          });
        }  else if (treatment === 'Relax' || treatment === 'EvoRelax') {
          this.getVideoDuration(ev.eventSerie.item.videoId).then((dur: number) => {
            if (dur) {
              const splittedDuration: string[] = dur.toString().split('.');
              ev.duration = parseInt(splittedDuration[0], 10);
            }
            else {
              ev.duration = 0;
            }
          }).catch((err) => {
            console.error(err);
            ev.duration = 0;
          });
        }
      });

      const sortedEvents: Event[] = events.sort(function(a,b) {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        if (dateA > dateB) return 1;
        if (dateA < dateB) return -1;
        return 0;
      });
      this.events$$.next(sortedEvents);
    });
  }

  /**
   * Calculates the total duration of the exercise (without the preparation time).
   * @param event   The exercise event
   * @returns       The duration of the exercise
   */
  private getExerciseDuration(event: Event): number {
    const eventSeries = event.eventSerieSnapshot ? event.eventSerieSnapshot : event.eventSerie;

    if (!(eventSeries.configCountSets > 0 && eventSeries.configCountReps > 0 && eventSeries.configDuration > 0
      && eventSeries.configCountSets && eventSeries.configTimeBetweenSets)) {
      return 0;
    }

    const setDuration = eventSeries.configCountSets * eventSeries.configCountReps * eventSeries.configDuration;
    const breakDuration = (eventSeries.configCountSets - 1) * eventSeries.configTimeBetweenSets;
    return setDuration + breakDuration;
  }

  /**
   * Get duration of the audio file of an exercise
   *
   * @param audioid   Path to audio file
   * @returns         Promise for the duration of the audio file
   */
  private async getAudioDuration(audioid: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const audio = new Audio(audioid);
      audio.preload = 'metadata';
      audio.onloadedmetadata = () => {
        audio && audio.duration ? resolve(audio.duration) : reject('Error getting the audio duration!');
      };
      audio.onerror = () => {
        reject('Error getting the audio duration!');
      };
    });
  }

  /**
   * Get duration of the audio file of an exercise
   *
   * @param videoId   Path to audio file
   * @returns         Promise for the duration of the video file
   */
  private async getVideoDuration(videoId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.setAttribute('src', videoId);
      video.preload = 'metadata';
      video.onloadedmetadata = () => {
        (video && video.duration) ? resolve(video.duration) : reject('Error getting the video duration!');
      };
      video.onerror = () => {
        reject('Error getting the video duration!');
      };
    });
  }

  /**
   * Get an Observable of the stored event list.
   *
   * @returns     The stored events
   */
  public getEvents(): Observable<Event[]> {
    return this.events$;
  }

  /**
   * Get an event by its id.
   *
   * @param id  The id of the event
   * @returns   Http-Response is the event
   */
  public getEventById(id: string): Observable<Event> {
    return this.api.get('event/getEventById', { id: id });
  }

  /**
   * Post the result data and the recorded videos.
   *
   * @param result  The result of the exercise
   * @param videos        The recorded videos
   */
  public addResultEvent(result: Result): void {
    // Remove event on client
    this.removeEventById(result.event.id);

    // POST ResultPhysio to server (3 attempts)
    this.api.post('result/createNewResult', result, null, 5).subscribe(
      () => { },
      (err) => {
        console.error(err);
      }
    );
  }

  /**
   * Removes an event from the list of events.
   *
   * @param eventId   The id of the event that is being removed
   */
  public removeEventById(eventId: string): void {
    let events: Event[] = this.events$$.value;
    events = events.filter(event => event.id !== eventId);
    this.events$$.next(events);
  }

  public query(params?: any): Event[] {
    if (!params) {
      return this.events$$.value;
    }

    return this.events$$.value.filter((item) => {
      for (let key in params) {
        let field = item[key];
        if (typeof field == 'string' && field.toLowerCase().indexOf(params[key].toLowerCase()) >= 0) {
          return item;
        } else if (field == params[key]) {
          return item;
        }
      }
      return null;
    });
  }

}
