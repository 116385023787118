import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { WebsocketProvider } from '../websocket/websocket';

import { Api, User } from '..';
import { Message } from '../../models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessageProvider {

  /**
   * available messages
   */
  private messages$$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public message$: Observable<any> = this.messages$$.asObservable();

  /**
   * available unread messages
   */
  private unreadMessages$$: BehaviorSubject<Message[]> = new BehaviorSubject(undefined);
  public unreadMessages$: Observable<Message[]> = this.unreadMessages$$.asObservable();

  /**
   * the logged in user
   */
  private loggedInUser: any;

  public messages: Subject<any>;

  // Our constructor calls our wsService connect method
  constructor(
    private wsService: WebsocketProvider,
    private api: Api,
    private user: User
  ) {

    this.loggedInUser = this.user.user;
    //console.log(this.loggedInUser);
    /**
     * get all unread messages for logged in user
     */
    const apiData = {
      id : this.loggedInUser?.id
    };
    this.api.get('message/getUnreadMessagesForPatientById', apiData)
      .subscribe((res: any) => {
        this.unreadMessages$$.next(res);
      }, err => {
        console.error('Error fetching Unread Messages for user', this.loggedInUser.id);
      });

    // listen to messages from socket
    this.messages = <Subject<any>>this.wsService
      .getMessages()
      .pipe(map((response: any): any => {
        return response;
      }));
  }

  /**
   * send message to socket
   * @params message
   */
  public sendMsg(msg: Message): void {
    this.messages.next(msg);
  }

  /**
   * set message as unread
   * @params unread message
   */
  public setUnreadMessage(message: Message): void {
    let unread: Message[] = this.unreadMessages$$.value;
    if (!unread) {
      unread = [];
    }
    unread.push(message);
    this.unreadMessages$$.next(unread);
  }

  /**
   * @returns unread messages
   */
  public getUnreadMessage(): Observable<Message[]> {
    return this.unreadMessages$;
  }

  /**
   * request to db.
   * mark all messages for patient as read
   * @param patientid
   */
  public markMessagesForPatientAsReadInDB(patientid: string): void {
    this.api.post('message/setMessageAsReadForId', {id: patientid})
      .subscribe((res: any) => {
        this.markMessagesForPatientAsRead(patientid);
      }, err => {
        console.error('Error fetching Unread Messages for user', this.loggedInUser.id);
      });
  }

  /**
   * mark all messages for patient as read
   * @param patientid
   */
  public markMessagesForPatientAsRead(patientid: string): void {
    let unread: Message[] = this.unreadMessages$$.value;
    const messages: Message[] = unread.filter((msg: Message) => msg.idReceiver !== patientid);
    this.unreadMessages$$.next(messages);
  }

  /**
   * get all messages for patient
   * @param id
   */
  public getAllMessagesForId(id: string): Observable<any>{
    return this.api.get('message/getAllMessagesForSenderAndReceiverById', {id: id});
  }

  /**
   * post new message
   * @params all properties for message
   */
  public postNewMessage(idSender: string, idReceiver: string, type: string, content: string, nameSender: string, fileName: string): Observable<any> {
    return this.api.post('message/createNewMessage', {
      message: content,
      filename: fileName,
      idsender: idSender,
      idreceiver: idReceiver,
      namesender: nameSender,
      type: type
    });
  }

}
