import {Component, OnInit, ViewChild} from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import  QrScanner from 'qr-scanner';
import {User} from "../services";
import {ModalPatientDeclarationPage} from "../modal-patient-declaration/modal-patient-declaration.page";
import {ModalPatientReminderPage} from "../modal-patient-reminder/modal-patient-reminder.page";
import {ModalNewPasswordPage} from "../modal-new-password/modal-new-password.page";
import {Router} from "@angular/router";
import {NotificationProvider} from "../services/notification/notification";
import {LoadingProvider} from "../services/loading/loading";
import {Storage} from "../services/storage/storage";
import {ResultEventProvider} from "../services/result-event/result-event";
import {WebsocketProvider} from "../services/websocket/websocket";


@Component({
  selector: 'app-page-qrcode-modal',
  templateUrl: 'modal-qrcode.page.html',
  styleUrls: ['./modal-qrcode.page.scss']
})
export class ModalQrCodePage {

  @ViewChild('videoElem')
  videoElem: any;

  public hasCamera: boolean = false;
  public showError: boolean = false;
  public qrScanner: QrScanner;


  constructor(
    public modalController: ModalController,
    public navCtrl: NavController,
    public user: User,
    private readonly translate: TranslateService
  ) {

  }

  ngAfterViewInit() {
    QrScanner.hasCamera().then(r => {
      if(r) {
        this.hasCamera = true;
        this.startScan();
      } else {
        this.showError = true;
      }
      })
      .catch(e => {
        this.showError = true;
      });
  }

  public async startScan() {
    this.qrScanner = new QrScanner(this.videoElem.nativeElement, async result => {
      this.qrScanner.stop();
      await this.modalController.dismiss(result);
    });
    void this.qrScanner.start();
  }

  public cancel(): void {

    if (this.qrScanner) {
      this.qrScanner.destroy();
      this.qrScanner = null;
    }

    void this.modalController.dismiss();
  }






}

