import {Component, Injectable, ElementRef, Renderer2, ChangeDetectorRef, OnInit} from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { Platform } from '@ionic/angular';
declare var navigator: any;

@Component({
  selector: 'check-connection',
  templateUrl: 'check-connection.html'
})
@Injectable({
  providedIn: 'root'
})
export class CheckConnectionComponent implements OnInit {

  public text: string;
  public hasConnection: boolean;
  public runCheck: boolean;

  constructor(public network: Network, private platform: Platform, private cdRef:ChangeDetectorRef, public elementRef: ElementRef, public renderer: Renderer2) {
    if (!this.platform.is("mobile"))
      return; // skip wlan handling for desktop
    let networkState = navigator.connection.type; // e.g. "wifi"
   console.log("NetworkState: ", networkState);
   if(networkState === "none") {
    this.hasConnection = false;
   } else {
    this.hasConnection = true;
   }
   this.network.onDisconnect().subscribe(() => {
      this.hasConnection = false;
      console.log("this.network.onDisconnect()");
      this.cdRef.detectChanges();
   })
   this.network.onConnect().subscribe(() => {
      this.hasConnection = true;
      console.log("this.network.onConnect()");
      this.cdRef.detectChanges();
   });
  }

  ngOnInit() {
    this.hasConnection = this.hasConnection;
   this.cdRef.detectChanges();
   console.log("Detected changes in ngOnInit for hasConnection: ", this.hasConnection);
  }
}
