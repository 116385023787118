import { NgModule } from '@angular/core';
import { LocaleDatePipe } from './locale-date/locale-date';
import { MinuteSecondsPipe } from './minute-seconds/minute-seconds';
import { SafeHtmlPipe } from "./safe-html/safe-html.pipe";
@NgModule({
	declarations: [LocaleDatePipe, MinuteSecondsPipe, SafeHtmlPipe],
	imports: [],
	exports: [LocaleDatePipe, MinuteSecondsPipe, SafeHtmlPipe]
})
export class PipesModule {}
