import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class NotificationProvider {

  private toast: ToastController = null;

  constructor(
    private toastCtrl: ToastController
  ) { }

  /**
   * present toast
   *
   * @param text the text to desiplayed
   * @param dur optional time to present toast
   */
  public presentToast(text: string, dur?: number) {
    const toastData: any = {
      message: text,
      duration: dur || 3000,
      position: 'top',
      cssClass: 'toast-notification'
    };
    this.showToast(toastData);
  }

  /**
   * close toast
   */
  public dismissToast(): void {
    if (this.toast) {
      this.toast.dismiss();
    }
  }

  /**
   * show toast
   *
   * @param data toast options for showing toast
   */
  async showToast(data: any) {
    const toast = await this.toastCtrl.create({
      message: data.message,
      position: 'top',
      duration: 3000,
      buttons: [
        {
          text: 'O.k.',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    });
    await toast.present();

    await toast.onDidDismiss().then(()=>{});
  }

}
